import Vue from 'vue/dist/vue.esm'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faAngleLeft, faAngleRight, faArrowRightArrowLeft, faArrowUpRightFromSquare, faBars, faBarsProgress, faBrowser, faBuilding, faBullhorn, faChartLine, faCheck, faChevronLeft, faChevronRight, faCircleCheck, faCircleDollar, faCircleExclamation, faCircleInfo, faCircleQuestion, faCircleUser, faCircleXmark, faCreditCard, faFolders, faGridHorizontal, faHouse, faInboxFull, faMagnifyingGlass, faPlay, faPlus, faRectangleHistory, faRotate, faSackDollar, faSort, faUserGroup, faUserPlus, faUsers, faVideo, faXmark, faTowerBroadcast } from '@fortawesome/pro-solid-svg-icons'
import { faArrowTrendUp, faArrowTrendDown, faChevronDown } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faAngleLeft)
library.add(faAngleRight)
library.add(faArrowRightArrowLeft)
library.add(faArrowTrendUp)
library.add(faArrowTrendDown)
library.add(faArrowUpRightFromSquare)
library.add(faBars)
library.add(faBarsProgress)
library.add(faBrowser)
library.add(faBuilding)
library.add(faBullhorn)
library.add(faChartLine)
library.add(faCheck)
library.add(faChevronDown)
library.add(faChevronLeft)
library.add(faChevronRight)
library.add(faCircleCheck)
library.add(faCircleDollar)
library.add(faCircleExclamation)
library.add(faCircleInfo)
library.add(faCircleQuestion)
library.add(faCircleUser)
library.add(faCircleXmark)
library.add(faCreditCard)
library.add(faFolders)
library.add(faGridHorizontal)
library.add(faHouse)
library.add(faInboxFull)
library.add(faMagnifyingGlass)
library.add(faPlay)
library.add(faPlus)
library.add(faRectangleHistory)
library.add(faRotate)
library.add(faSackDollar)
library.add(faSort)
library.add(faUserGroup)
library.add(faUserPlus)
library.add(faUsers)
library.add(faVideo)
library.add(faXmark)
library.add(faTowerBroadcast)

Vue.component('font-awesome-icon', FontAwesomeIcon)
