<template>
  <li>
    <a class="block hover:bg-neutral-50" :href="href">
      <div class="flex items-center px-4 py-4 sm:px-6">
        <div class="min-w-0 flex-1 flex items-center">
          <slot />
        </div>
        <div>
          <font-awesome-icon class="h-5 w-5 text-neutral-400" :icon="['fas', 'angle-right']" />
        </div>
      </div>
    </a>
  </li>
</template>

<script>
export default {
  props: {
    href: String
  }
}
</script>
